import '../App.css';
import React, { useEffect } from 'react'


export default function WebCarMuseum() {
    useEffect(() =>{
        document.title = "Projetos"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Projects
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/widgets" className='list-style-item-inner nohyperlink'>
                                        Widgets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">A Web Car Museum</h2>
                            <p>
                                During the class Internet Applications I, on the first semester of the second year, we, students, had to develop a website using plain HTML, CSS and JavaScript.
                                As such, me and my group decided to opt for a showcase of cars on the internet. 
                            </p>
                            <p>
                                The website includes:
                                <ul>
                                    <li>
                                        A slideshow at the top of the page containing the logos of almost 20 different brands of cars.
                                    </li>
                                    <li>
                                        Four categories of cars:
                                        <ul>
                                            <li>
                                                Classics
                                            </li>
                                            <li>
                                                Sports
                                            </li>
                                            <li>
                                                Super
                                            </li>
                                            <li>    
                                                4x4 / All terrain
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Each unique category contains 5 different cars specifically chosen by the members of the group
                                    </li>
                                    <li>
                                        A dark mode feature that remains even if the page is refreshed or closed.
                                    </li>
                                    <li>
                                        An extra page, as an easter egg, that contains one special vehicle chosen by each member of the group, as seen on the third image.
                                    </li>
                                </ul>
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="./Project3/Project3-1.png" target="_blank">
                                        <img src='/projects/Project3/Project3-1.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="./Project3/Project3-2.png" target="_blank">
                                        <img src='/projects/Project3/Project3-2.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                            </div>
                            <div style={{height: "30px"}}>

                            </div>
                            <div className="image-gallery">
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="./Project3/Project3-3.png" target="_blank">
                                        <img src='/projects/Project3/Project3-3.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-double">
                                    <a style={{cursor: "pointer"}} href="./Project3/Project3-4.png" target="_blank">
                                        <img src='/projects/Project3/Project3-4.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
