import { Route, Routes } from "react-router-dom";
import LandingPage from './Pages/LandingPage';
import ProjectsLandingPage from './Pages/ProjectsLandingPage'
import Projects from './Discontinued/Projects'
import Contact from './Discontinued/Contact'
import HotWheels from "./Pages/HotWheels";
import Widgets from "./Pages/Widgets";
import React from 'react'

export default function MainPage(){
    return (
        <div>
            <Routes>
                <Route path='/' element={<LandingPage></LandingPage>}>
                </Route>
                <Route path='projects/*' element={<ProjectsLandingPage/>}>
                </Route>
                <Route path='contact' element={<Contact/>}>
                </Route>
                <Route path='hotwheels' element={<HotWheels/>}>
                </Route>
                <Route path='widgets' element={<Widgets/>}>
                </Route>
            </Routes>
        </div>
    )
}