import '../App.css';
import React, { useEffect } from 'react'


export default function SPPIF() {
    useEffect(() =>{
        document.title = "Projetos"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Projects
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/widgets" className='list-style-item-inner nohyperlink'>
                                        Widgets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">SPPIF</h2>
                        </div>
                        <p>
                            Sistema de Proteção e Prevenção de Incêndios Florestais (<span className='special-link'>Forest Fire Protection and Prevention System</span>) is a work in progress project being developed on the class Embedded Systems.
                            <br></br>
                            This system, as the name refers, consists of a group of devices/sensors to help detect and prevent forest fires. They are:
                            <ul>
                                <li>
                                    1x Raspberry Pi - To wireless receive data and store it on a database.
                                </li>
                                <li>
                                    1x Raspberry Pi Camera - To record the forest and with an algorithm/AI detect smoke or fire.
                                </li>
                                <li>
                                    1x ESP8266 - To read the data from the sensors and send it to the Raspberry Pi
                                </li>
                                <li>
                                    1x Arduino UNO - To configure and code the ESP8266
                                </li>
                                <li>
                                    1x DHT11 - To read the temperature and humidity
                                </li>
                                <li>
                                    1x MQ-135 - To read the amount of PPM in the air of carbon monoxide.
                                </li>
                            </ul>
                            <p>
                                All these systems combined will be a great way to monitor a specific zone and help prevent desasters like wild fires.
                            </p>
                            <p>
                                My role in this project was to help develop the source code for the ESP8266 to read the data from the two sensors and send it, over HTTP, to the Raspberry Pi.
                                <br></br>
                                Below, on the first image, we can see a power bank supplying electricity to the ESP8266. This microcontroller is reading the data from the MQ-135 (left) and DHT11 (right) sensors.
                                <br></br>
                                The second and third image shows our final prototype with all the holes for the sensors and USB connections, alonsige the Raspberry Pi on top (missing the camera). 
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./SPPIF/Image1.jpg" target="_blank">
                                        <img src='/projects/SPPIF/Image1.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./SPPIF/Image2.jpg" target="_blank">
                                        <img src='/projects/SPPIF/Image2.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./SPPIF/Image3.jpg" target="_blank">
                                        <img src='/projects/SPPIF/Image3.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                            </div>
                            <p>
                                The image below shows the result of the project. A website that will display a livestream of what the Raspberry Pi's camera is capturing, the information the sensors register (temperature, humidity and PPM of CO2) and some photos of possible incidents at the bottom of the page.
                                <br></br>
                                This website also contains some statistical graphics to show how the temperature changes throughout the day.
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-single">
                                    <a style={{cursor: "pointer"}} href="./SPPIF/Website.png" target="_blank">
                                        <img src='/projects/SPPIF/Website.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                            </div>
                            <br></br>
                            <div className="image-gallery">
                                <div className="image-item-single">
                                    <a style={{cursor: "pointer"}} href="./SPPIF/Website2.png" target="_blank">
                                        <img src='/projects/SPPIF/Website2.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </section>


            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
