import React, { useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import NavBar from '../NavBar'

export default function Widgets() {
    const APIKEY = '052a76c8649242f8527d0813d3ad097e';

    const [WeatherData, setWeatherData] = useState(null); // Initialize as null to check if data exists
    const [City, setCity] = useState('');
    const [Country, setCountry] = useState('');

    useEffect(() => {
        document.title = "Widgets";
    }, []);

    const loadWeather = () => {
        if (!City || !Country) {
            alert("Please enter both city and country.");
            return;
        }
        const url = `https://api.openweathermap.org/data/2.5/weather?q=${City},${Country}&APPID=${APIKEY}&units=metric`;
        console.log(url);
        axios.get(url)
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    setWeatherData(res.data);
                } else {
                    alert("Error fetching weather data!");
                }
            })
            .catch(error => {
                alert("Error: " + error);
            });
    };

    return (
        <div className="page-wrapper">
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className="list-style">
                                <li className="list-style-item">
                                    <a href="/" className="list-style-item-inner nohyperlink" style={{ marginLeft: "-10px" }}>
                                        About me
                                    </a>
                                </li>
                                <li className="list-style-item">
                                    <a href="/projects" className="list-style-item-inner nohyperlink">
                                        Projects
                                    </a>
                                </li>
                                <li className="list-style-item">
                                    <a href="/widgets" className="list-style-item-inner special-link">
                                        Widgets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section>
                <div className="container py-5">
                    <div className="row">
                        <div className='col-md-6'>
                            {/*Caixa com o weather search*/}
                            <div className="mb-4">
                                <div className="box border p-4" style={{ borderRadius: "20px" }}>
                                    <h4 style={{ color: "#ff784e" }}>Search Weather</h4>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        loadWeather();
                                    }}>
                                        <div className="form-group">
                                            <label htmlFor="city">City</label>
                                            <input
                                                type="text"
                                                id="city"
                                                className="form-control"
                                                value={City}
                                                onChange={(e) => setCity(e.target.value)}
                                                placeholder="Enter city"
                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="country">Country</label>
                                            <input
                                                type="text"
                                                id="country"
                                                className="form-control"
                                                value={Country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                placeholder="Enter country"
                                            />
                                        </div>
                                        <button className="btn mt-3" type='submit' onClick={loadWeather} style={{ backgroundColor: "orange" }}>
                                            Get Weather
                                        </button>
                                    </form>
                                </div>
                            </div>
                            {/*Caixa com o RNG*/}
                            <div className=" mb-4">
                                <div className="box border p-4" style={{ borderRadius: "20px" }}>
                                    <h4 style={{ color: "#ff784e" }}>Random Number Generator</h4>
                                    <RandomGenerators></RandomGenerators>
                                </div>
                            </div>
                            <div className=" mb-4">
                                <div className="box border p-4" style={{ borderRadius: "20px" }}>
                                    <h4 style={{ color: "#ff784e" }}>Random Quote</h4>
                                    <RandomQuote></RandomQuote>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6'>
                            {/*Caixa com os resultados do Weather Search*/}
                            <div className="mb-4">
                                <div className="box border p-4" style={{ borderRadius: "20px" }}>
                                    <h4 style={{ color: "#ff784e" }}>Weather Results</h4>
                                    {WeatherData ? (
                                        <div>
                                            <p><strong>City:</strong> {WeatherData.name}</p>
                                            <p><strong>Country:</strong> {WeatherData.sys.country}</p>
                                            <p><strong>Temperature:</strong> {WeatherData.main.temp} °C</p>
                                            <p><strong>Wind Speed:</strong> {WeatherData.wind.speed} m/s</p>
                                            <p><strong>Description:</strong> {WeatherData.weather[0].description}</p>
                                            <p><strong>Current Time:</strong> {Date(WeatherData.dt)}</p>
                                        </div>
                                    ) : (
                                        <p>No data available. Please search for weather.</p>
                                    )}
                                </div>
                            </div>
                            {/*Caixa com o desenho*/}
                            <div className=" mb-4">
                                <div className="box border p-4" style={{ borderRadius: "20px" }}>
                                    <h4 style={{ color: "#ff784e" }}>Art</h4>
                                    <Art></Art>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

    function RandomGenerators() {
        const [randomNumber, setRandomNumber] = useState(null);
        const [randomString, setRandomString] = useState('');
        const [coinResult, setCoinResult] = useState('');

        const [min, setMin] = useState('');
        const [max, setMax] = useState('');

        const generateRandomNumber = () => {
            if (min !== '' && max !== '') {
                const num = Math.floor(Math.random() * (parseInt(max) - parseInt(min) + 1)) + parseInt(min);
                setRandomNumber(num);
            }
        };

        const [stringLength, setStringLength] = useState('');

        const generateRandomString = () => {
            if (stringLength !== '' && stringLength <= 15 && stringLength > 0) {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789[]!@#$%^&*()_+-=}{,.<>/;:"?';
                let result = '';
                for (let i = 0; i < parseInt(stringLength); i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                setRandomString(result);
            }
            if (stringLength > 15 || stringLength < 1) {
                alert("The length has to be between 1 and 15")
            }
        };

        const flipCoin = () => {
            const result = Math.random() < 0.5 ? 'Heads' : 'Tails';
            setCoinResult(result);
        };

        return (
            <div className="random-generators">
                <div className="generator">
                    <h4>Random Number</h4>
                    <div className='d-flex'>
                        <input type="number" placeholder="Min" value={min} onChange={(e) => setMin(e.target.value)} className="input-field" />
                        <input type="number" placeholder="Max" value={max} onChange={(e) => setMax(e.target.value)} className="input-field" />
                    </div>
                    <button onClick={generateRandomNumber} className="generate-btn">Generate</button>
                    {randomNumber !== null && <p>Random Number: {randomNumber}</p>}
                </div>

                <div className="generator">
                    <h4>Random String</h4>
                    <input type="number" placeholder="String Length" value={stringLength} onChange={(e) => setStringLength(e.target.value)} className="input-field" />
                    <button onClick={generateRandomString} className="generate-btn">Generate</button>
                    {randomString && <p>Random String: {randomString}</p>}
                </div>

                <div className="generator">
                    <h4>Coin Flip</h4>
                    <button onClick={flipCoin} className="generate-btn">Flip Coin</button>
                    {coinResult && <p>Result: {coinResult}</p>}
                </div>
            </div>
        );
    }

    function Art() {
        const [grid, setGrid] = useState(
            () => {
                const storedGrid = localStorage.getItem('grid');
                return storedGrid ? JSON.parse(storedGrid) : Array(50).fill(null).map(() => Array(50).fill('white'));
            }
        );
        const [selectedColor, setSelectedColor] = useState(
            () => {
                const storedColor = localStorage.getItem('selectedColor');
                return storedColor || 'black';
            }
        );

        const handlePixelClick = (row, col) => {
            const newGrid = grid.map((r, rowIndex) =>
                r.map((color, colIndex) =>
                    rowIndex === row && colIndex === col ? selectedColor : color
                )
            );
            setGrid(newGrid);
        };

        useEffect(() => {
            localStorage.setItem('grid', JSON.stringify(grid));
        }, [grid]);

        useEffect(() => {
            localStorage.setItem('selectedColor', selectedColor);
        }, [selectedColor]);

        return (
            <div className="box p-4" style={{ borderRadius: '20px' }}>
                <div>
                    <h3 className="text-center">Pixel Art Maker</h3>
                    <div className="mb-3">
                        <label htmlFor="colorPicker">Pick a color:</label>
                        <input id="colorPicker" type="color" value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} className="form-control mt-2"/>
                    </div>
                    <div className="box p-4" style={{ borderRadius: '20px', overflow: 'auto' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(50, 10px)',gap: '1px', maxWidth: '100%', overflow: 'auto'}}>
                                {grid.map((row, rowIndex) =>
                                    row.map((color, colIndex) => (
                                        <div key={`${rowIndex}-${colIndex}`} onClick={() => handlePixelClick(rowIndex, colIndex)}
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: color,
                                                border: '1px solid #ccc',
                                            }}
                                        >    
                                        </div>
                                    ))
                                )}
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    function RandomQuote(){
        const [Quote, setQuote] = useState([]);

        useEffect(() => {
            loadQuote();
        }, []);

        function loadQuote(){
            axios.get('https://go-quote.azurewebsites.net/ramdom-quote')
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    let quote = String(res.data).split(":");
                    setQuote(quote);
                } else {
                    alert("Error fetching weather data!");
                }
            })
            .catch(error => {
                alert("Error: " + error);
            });
        }

        return Quote.map((part, index)=>{
            if(index === 0 && part.includes("Quote")){
                return
            }
            else if(index === 1 && part.includes("Author")){
                part = part.slice(0, part.length -7)
                return <p>{part}</p>
            }
            else if(index === 2){
                part = part.slice(0, part.length -5)
                return <p>Author: {part}</p>
            }
        })
    }
}
