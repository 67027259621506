import '../App.css';
import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import SPPIF from '../Projects/SPPIF'
import IntegratedProject from '../Projects/IntegratedProject';
import WebCarMuseum from '../Projects/WebCarMuseum'
import ArduinoProject from '../Projects/ArduinoProject';

export default function ProjectsLandingPage() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<LandingPage></LandingPage>}>
                </Route>
                <Route path='SPPIF' element={<SPPIF></SPPIF>}>
                </Route>
                <Route path='integrated-project' element={<IntegratedProject></IntegratedProject>}>
                </Route>
                <Route path='car-museum' element={<WebCarMuseum></WebCarMuseum>}>
                </Route>
                <Route path='arduino' element={<ArduinoProject></ArduinoProject>}>
                </Route>
            </Routes>
        </div>
    )

    function LandingPage(){
        return(
            <div className="page-wrapper">
                <header className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <ul className="list-style">
                                    <li className="list-style-item">
                                        <a href="/" className="list-style-item-inner nohyperlink" style={{ marginLeft: "-10px" }}>
                                            About me
                                        </a>
                                    </li>
                                    <li className="list-style-item">
                                        <a href="/projects" className="list-style-item-inner special-link">
                                            Projects
                                        </a>
                                    </li>
                                    <li className="list-style-item">
                                    <a href="/widgets" className="list-style-item-inner nohyperlink">
                                        Widgets
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
    
                <section id="about" className="py-5 content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">List of projects</h2>
                                <ul style={{ margin: "10px"}}>
                                    <Projetos></Projetos>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
    
                <footer className="footer text-center py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                                <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

    function Projetos(){
        let projetos = [
            ['SPPIF (Sistema de Prevenção e Proteção contra Incêndios Florestais)', '/projects/SPPIF'],
            ['Blog/Reddit Website and Mobile App (Integrated Project)', '/projects/integrated-project'],   
            ['Arduino Temperature, Humidity and Light Project', '/projects/arduino'],
            ['Web Car Museum', '/projects/car-museum'],
        ]
        return projetos.map((project) =>{
            return(
                <li style={{ margin: "10px", marginBottom: "20px" }}>
                    <div>
                        <a href={project[1]} className='special-link' style={{color: 'white'}}>
                            <h4 className="clickable">{project[0]}</h4>
                        </a>
                    </div>
                </li>
            )
        })
    }
}


