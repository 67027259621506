import '../App.css';
import React, { useEffect } from 'react'


export default function IntegratedProject() {
    useEffect(() =>{
        document.title = "Projetos"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Projects
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/widgets" className='list-style-item-inner nohyperlink'>
                                        Widgets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">Blog/Reddit Website and Mobile App</h2>
                            <p>
                                This project was part of a class called Integrated Project. It was proposed by a local tech company to challenge students to improve their web, backend, and mobile programming skills.
                                <br />
                                It consists of a website for admin usage that includes a backoffice for management, a mobile application (Android) for users, and a database to store all data.
                            </p>
                            <div className="image-gallery">
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-1.png" target="_blank">
                                        <img src='/projects/Project1/Project1-1.png' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-2.png" target="_blank">
                                        <img src='/projects/Project1/Project1-2.png' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                                <div className="image-item">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-3.png" target="_blank">
                                        <img src='/projects/Project1/Project1-3.png' className="responsive-image" alt="Project Screenshot 3"/>
                                    </a>
                                </div>
                            </div>
                            <div style={{height: "30px"}}>

                            </div>
                            <div className="image-gallery">
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-4.jpg" target="_blank">
                                        <img src='/projects/Project1/Project1-4.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-5.jpg" target="_blank">
                                        <img src='/projects/Project1/Project1-5.jpg' className="responsive-image" alt="Project Screenshot 2"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project1/Project1-6.jpg" target="_blank">
                                        <img src='/projects/Project1/Project1-6.jpg' className="responsive-image" alt="Project Screenshot 3"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
