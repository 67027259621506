import '../App.css';
import React, { useEffect } from 'react'


export default function ArduinoProject() {
    useEffect(() =>{
        document.title = "Projetos"
    }, [])
    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className='list-style'>
                                <li className='list-style-item'>
                                    <a href="/" className='list-style-item-inner nohyperlink' style={{marginLeft: "-10px"}}>
                                        About me
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/projects" className='list-style-item-inner special-link'>
                                        Projects
                                    </a>
                                </li>
                                <li className='list-style-item'>
                                    <a href="/widgets" className='list-style-item-inner nohyperlink'>
                                        Widgets
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">Arduino Temperature, Humidity and Light Project</h2>
                            <p>
                                This project was made by me to learn and improve my skills on embedded systems.
                                <br />
                                It consists of:
                                <ul>
                                    <li>
                                        1 Arduino
                                    </li>
                                    <li>
                                        3x 74HC595 8 bit shift registers
                                    </li>
                                    <li>
                                        10x Yellow LEDs
                                    </li>
                                    <li>
                                        1x DHT11 Temperature and Humidity Sensor
                                    </li>
                                    <li>
                                        1x Potenciometer
                                    </li>
                                    <li>
                                        1x 4 Digit 7 Segment Display
                                    </li>
                                </ul>
                                This small and fun project allowed me to learn how:
                                <br></br>
                                <ul>
                                    <li>
                                        To use the 74HC595 8 bit shift register, that allows us to get 8 digital output pins while only using 3 pins on the arduino.
                                    </li>
                                    <li>
                                        Daisy chaining two shift registers work to create 16 output pins while still only using 3 pins from the arduino.
                                    </li>
                                    <li>
                                        To read data from the DHT11 Temperature and Humidity sensor.
                                    </li>
                                    <li>
                                        To manage the 8 output pins from the shift register and make "christmas tree" light festival using the 10 leds.
                                    </li>
                                    <li>
                                        To, using the 2 shift registers, display the correct data, such as temperature and humidity on the display
                                        <br></br>
                                        As can be seen on the third photo, the 24 is the temperature in celsius and the 61 is the humidity in percentage.
                                    </li>
                                    <li>
                                        A potenciometer can be used to switch between modes and displaying different things on the display or lights.
                                    </li>
                                </ul>
                            </p>
                            <div className="image-gallery">
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project2/Project2-1.jpg" target="_blank">
                                        <img src='/projects/Project2/Project2-1.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project2/Project2-3.jpg" target="_blank">
                                        <img src='/projects/Project2/Project2-3.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                                <div className="image-item-vertical">
                                    <a style={{cursor: "pointer"}} href="./Project2/Project2-2.jpg" target="_blank">
                                        <img src='/projects/Project2/Project2-2.jpg' className="responsive-image" alt="Project Screenshot 1"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
